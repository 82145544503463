.sidebar-medium-status-container {
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mpac-green)
}

.sidebar-small-item {
    display: flex;
    padding: 3px 0;
}

.sidebar-small-item-text {
    margin: 7px 0 7px 10px;
}

.sidebar-small-item-text:last-child {
    margin-bottom: 10px;
}

@media screen and (min-width: 2560px) {
    .sidebar-small-item {
        padding: 0.3rem 0;
    }

    .sidebar-small-item-text {
        margin: 0.5rem 0 0.5rem 0.6rem;
    }
}

.sidebar-small-item-active {
    color: var(--mpac-main-100);
    font-weight: bold;
}

.sidebar-small-item-inactive {
    cursor: pointer;
}