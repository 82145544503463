.sidebar-item-large-container {
    width: 100%;
}

.sidebar-status-container {
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mpac-green);
    font-size: large;
}

.sidebar-medium-item {
    display: flex;
    padding: 5px 0;
    border-radius: 5px;
}

.sidebar-medium-item-text {
    margin: 5px 0 5px 10px;
}

.sidebar-medium-item-children {
    display: flex;
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
}

@media screen and (min-width: 2560px) {
    .sidebar-medium-item {
        padding: 0.3rem 0;
        border-radius: 0.3rem;
    }
    
    .sidebar-medium-item-text {
        margin: 0.3rem 0 0.3rem 1.5rem;
    }
    
    .sidebar-medium-item-children {
        padding: 0.3rem 0;
        border-radius: 0.3rem 0.3rem 0 0;
    }
}

.sidebar-medium-item-active {
    font-weight: bold;
    background-color: var(--mpac-main-30);
}

.sidebar-medium-item-inactive {
    cursor: pointer;
}

.sidebar-item-small-root-container {
    background-color: white;
    border-radius: 0 0 5px 5px;
}