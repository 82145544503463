.anchor-to-button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    color: var(--mpac-light-grey);
    transition: color 200ms, text-shadow 200ms;
    text-wrap: nowrap;
}

.anchor-to-button:hover {
    text-shadow: 1px 1px var(--mpac-main-30);
    cursor: pointer;
    box-shadow: none;
    color: #0056b3;
    outline: none;
}