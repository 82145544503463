.unit-table {
    table-layout: fixed;
}
.unit-table-section {
    width: 190px;
}
.unit-table,
.unit-table th,
.unit-table td {
    border-radius: 0.1875rem !important;
}

.unit-root-level-tr > td {
    padding: 0 0.75em;
}
.half-border {
    border-spacing: 0;
}

.half-border,
.half-border td {
    border: 0.5px solid var(--mpac-main-30);
}

.valign-top {
    vertical-align: top;
}
.xsmall-input {
    width: 30px !important;
}

.small-input {
    width: 60px !important;
}

.mid-input {
    width: 120px !important;
}

.long-input {
    width: 180px !important;
}

.label-title {
    color: var(--mpac-main-100);
    padding: 5px 0px;
}

.label-cell {
    padding: 3px 0px;
}

.column-space-left {

}

.column-space-right {
    padding-right: 15px;
}

.land-lease {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
}

.unit-table .checkbox-container {
    background-color: var(--mpac-main-10);
    width: 19px;
    height: 19px;
}

.unit-table .radio-container {
    width: 19px;
    height: 19px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-right-10 {
    padding-right: 10px;
}

.no-wrap {
    text-wrap: nowrap;
}

.unit-table td select, .unit-input {
    width: 100%;
    border-radius: 3px;
    background: var(--mpac-main-10);
}

.unit-input:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
}

.unit-table select:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
}

.unit-table .input-error-message, .unit-table .form-error-message{
    margin: 0;
    font-size: 12px;
}

.highlighted-cell {
    background: var(--mpac-yellow) !important;
}

.link-div {
    padding: 15px 3px 15px 3px;
}