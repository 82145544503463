
.toast-info {
  background: #cce8ff;
  color: #000;
  /* background: var(--mpac-main-100); 
  color: #fff; */
}

.toast-success {
  background: #ddeedf;
  color: #000;
  /* background: var(--mpac-green); 
  color: #fff; */
}

.toast-warning {
  background: #fff7cc;
  color: #000;
  /* background: var(--mpac-yellow); 
  color: #fff; */
}

.toast-error {
  background: #f9d2d2;
  color: #000;
  /* background: var(--mpac-red); 
  color: #fff; */
}

.unit-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.disclaimer p {
  margin: 0;
}

.unit-table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.unit-table-text {
  margin: 0;
}

.unit-table-control-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unit-table-control-group button {
  border: none;
  border-radius: 0;
  background-color: var(--mpac-main-20);
  color: black;
  font-size: 1em;
  margin: 0.15em;
  padding: 0.5em 1em;
}

.popover-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 10px var(--mpac-main-30);
  font-size: 0.875rem;
  min-width: 10rem;
  padding: 1rem;
  z-index: 9;
}

.filter-popover .popover-content {
  position: absolute;
  margin-top: 0rem;
}

.filter-popper-column {
  display: flex;
  align-items: center;
  padding: 3px 0px;
}

.tenant-search-input {
  width: 14em;
  margin: 0;
}

.unit-table .edit-btn, .unit-table .delete-btn {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 1.15em;
}

.unit-table .edit-btn {
  color: black;
}

.no-wrap {
  text-wrap: nowrap;
}

.filter-table td {
  padding: 2px;
}

.lease-info-commercial {
  display: flex;
  margin: 20px 0;
}

.lease-info-commercial p {
  margin: 0 30px 0 0;
}

.lease-info-commercial #commercial-yes {
  margin-right: 1.25rem;
  width: 0.875rem;
  height: 0.875rem;
}

@media screen and (max-width: 822px){
  .unit-table-subcontainer {
    width: 40px;
  }
}

.unit-table-summary-wrapper {
  width: fit-content;
}

@media screen and (max-width: 1024px) {
  .unit-table-container {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .unit-table-container {
    width: 100%;
  }
}

.highlighted-mandatory-cell {
  background: var(--mpac-yellow) !important;
}