.helpcentre-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 4.1em);
  display: flex;
  flex-direction: row;
}

.helpcentre-definition {
  display: flex;
  flex-direction: column;
  width: 20vw;
  min-width: 290px;
  padding: 0.5em 1em 0.5em 1em;
  background-color: var(--mpac-main-10);
}

.helpcentre-definition div {
  background: transparent !important;
}

.helpcentre-definition a {
  height: 40px !important;
}

.helpcentre-definition ul {
  list-style-type: none;
}

.helpcentre-definition li {
  padding-left: 1em;
}

.definition {
  width: 100% !important;
  border: none !important;
}

.definition-title {
  color: var(--mpac-main-100);
  font-weight: bold;
}

.helpcentre-iframe {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0em 2em;
}

.iframe {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrap-text {
  text-wrap: wrap;
}