.current {
    font-weight: bold;
    color: var(--mpac-main-100);
}

.breadcrumbs-container {
    display: flex;
    flex-direction: row;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-right-5 {
    padding-right: 5px;
}
