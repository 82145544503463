.summary-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
}

.print-icon {
  color: #015190;
  font-size: 8em;
}

.print-wip-text {
  font-family: Quicksand, sans-serif;
  font-weight: 800;
  font-size: 1.15em;
  text-align: center;
  padding: 0.5em 0;
  width: 25%;
}

.certify {
  font-family: Quicksand, sans-serif;
  font-weight: 800;
  font-size: 1em;
  text-align: center;
  padding-bottom: 2em;
  width: 50%;
}

#certify-checkbox {
  margin: 0 10px !important;
  font-size: 1.25em;
  color: #333;
  cursor: pointer;
}
