.assisted-suite-breakdown-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.suite-breakdown-desc {
    color: black;
    font-size: smaller;
    font-weight: normal;
}

.assisted-suite-breakdown-header-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.assisted-suite-breakdown-btn-container {
    display: flex;
    gap: 5px;
}


.assisted-suite-breakdown-table {
    width: 100%;
}

.assisted-suite-breakdown-table input, .assisted-suite-breakdown-table select {
    border: none;   
    margin: 0;
    width: 100%;
    box-sizing: border-box;
}

.assisted-suite-breakdown-table td:nth-last-child(-n+2) button {
    width: 100%;
    background: transparent;
    border: none;
}

.assisted-suite-breakdown-table td:nth-last-child(-n+2) button:focus {
    box-shadow: none;
}


.assisted-suite-breakdown-table th {
    width: 13%;
}
.assisted-suite-breakdown-table th:first-child {
    width: 27%;
}

.assisted-suite-breakdown-table th:nth-last-child(-n+2) {
    width: 0%;
}