.menu-root {
    background-color: var(--mpac-main-10);
}

.submenu-root {
    background-color: var(--mpac-main-10);
}

.menu-item {
    background-color: transparent;
}

.current {
    font-weight: bold;
    color: var(--mpac-main-100);
}

.sidebar-container {
    height: 100%;
}