@import url('./common.css');
@import url('./component.css');
@import url('./validation.css');
@import url('./accordion.css');

body {
  margin: 0;
  font-family: 'Open Sans',Calibri,Arial,Sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--mpac-background);
  overflow: auto;
  font-size: 0.875rem;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.tsx style */
.session-modal>div{
  width: 300px;
  padding-right: 15px;
}
.session-modal-buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}
.session-modal-buttons button {
  margin-left: 10px;
}