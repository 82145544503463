@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner-parent{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(235,235,235,0.5);
    transition: background-color 200ms;
    z-index: 99;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr
}

.spinner-container>* {
    grid-row: 1;
    grid-column: 1;
}

.not-loading{
    z-index: -1;
    transition: background-color 200ms;
    background-color: transparent;
}