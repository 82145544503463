.percent-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.percent-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.percent-container input {
    width: 100%;
    height: 100%;
    margin: 0px;
    border: 0px;
    box-sizing: border-box;
}

.percent-container input,
.percent-container input:focus,
.percent-container input:hover {
    box-shadow: none;
}

.percent-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-align: right;
    border: 0px;
    margin: 0px;
}

.percent-symbol {
    padding: 0px 6px;
    text-wrap: nowrap;
    background: transparent;
}