.parking-sum-row-table {
    border: none;
    border-radius: 0 0 5px 5px;
}

.parking-sum-row td {
    border: none;
    background-color: var(--mpac-main-10);
    padding: 0.5em;
}

.parking-sum-row > *:not(:first-child) {
    text-align: center;
}