.datepicker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.datepicker-container {
    position: relative;
}

.datepicker-input {
    padding: 6px 36px 6px 12px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}

.datepicker-icon {
    position: absolute;
    /* margin-left: -25px;
    margin-top: 7px; */
    color: var(--mpac-main-100);
    top: 35%;
    right: 12px;
}

.react-datepicker-popper {
    z-index: 100000 !important;
}

.datepicker-input.form-error {
    border: 2px solid var(--mpac-darker-red) !important;
}

pre {
    text-wrap: wrap;
}