:root{
    --max-input-size: 300px
}

.locked-input {
 color: rgb(170, 170, 170);
}

.roll-inputs-parent-top {
 max-width: var(--max-input-size);
}

.roll-inputs-parent {
    display: grid;
    grid-template-columns: 3.2em 3.2em 3.7em 3.7em 5em 4.2em;
    grid-template-rows: 1fr;
}

.roll-input > input{
    max-width: unset;
    min-width: unset;
    display: block;
    width: 100%;
}

.roll-input {
    display: flex
}

.input {
    width: var(--max-input-size);
}

.table-input {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    border: none;
    text-align: center;
}

@media  screen and (max-width: 500px) {
    .input {
        width: 80%;
    }
}

.input.error {
    background-color: var(--mpac-darker-red);
}
.table.error {
    border: 2px solid var(--mpac-darker-red);
}
.table-error {
    border: 2px solid var(--mpac-darker-red) !important;
}

.green-check {
    color: var(--mpac-green);
    padding: 0px 10px
}

.red-cross {
    color: var(--mpac-darker-red);
    padding: 0px 10px
}

.input-error-message {
    margin-top: 0 !important;
    padding-left: 5px;
    color: var(--mpac-darker-red);
    font-size: 0.8rem;
}

.password-input {
    box-sizing: border-box;
    padding-right: 45px;
}
.show-password-button {
    margin-left: -42px;
    background: transparent;
    border: none;
}
.show-password-button:is(:hover, :focus) {
    box-shadow: none;
}

/* autocomplete */
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
  }
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 120px;
    left: 15px;
    right: 0;
    width: 370px;
    box-shadow: 1px 1px 15px;

  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }

  .hidden.autocomplete-items {
    display: none;
  }


.table-cell-input-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    padding-top: 10px;
    padding-bottom: 10px;
}