.start-body-container {
    width: 100%;
    align-content: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 19em - 40px);
}

.start-body {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.start-heading-section {
    max-width: 55rem;
    text-align: center;
}

.start-footer {
    margin-top: 0.5em;
}
.campaign-list-wrapper {
    width: fit-content;
    padding: 1em;
    align-self: center;
    margin-bottom: 0.5em;
}

.import-block {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.import-block button {
    margin: 0;
}

.start-page-return-btn {
    align-self: center;
}