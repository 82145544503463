/* DataTable */
.pier-datatable {
  border-spacing: 0;
  white-space: pre-line;
  width: 100%;
}
.pier-datatable th, .pier-datatable td{
  border: 0.03125rem solid var(--mpac-main-20);
}

.pier-datatable th {
  text-align: start;
  vertical-align: text-top;
  font-weight: bold;
  background-color: var(--mpac-main-10);
  padding: 0rem 0.5rem;
}

.pier-datatable td {

}

.pier-datatable input:hover,
.pier-datatable select:hover,
.pier-datatable input:focus,
.pier-datatable select:focus {
    box-shadow: none;
}

.pier-datatable-pagination {
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
}

.pier-datatable-pagination button {
  border-radius: 0;
  border: 0.03125rem solid var(--mpac-main-20);
  color: black;
  font-weight: normal;
}
.pier-datatable-pagination .button-submit {
  background-color: var(--mpac-main-20);
}
.pier-datatable-pagination .button-disabled {
  border: 0.03125rem solid var(--mpac-light-grey-border);
  color: var(--mpac-light-grey-border);
}
.pier-datatable-pagination .pier-table-page-button-font-back-pages:hover {
  cursor: default;
  box-shadow: none;
}
.pier-datatable-pagination select {
  margin: 0;
}

.border-input input[type=text],
.border-input input[type=number],
.border-input textarea {
  padding: 6px 12px;
  transition: box-shadow 200ms;
  margin: 0px;
  display: table-cell;
  width: 100%;
  border: 1px solid var(--mpac-main-30);
}
.border-input input[type=radio],
.border-input input[type=checkbox],
.border-input select {
  transition: box-shadow 200ms;
  margin: 0px;
  display: table-cell;
  border: 1px solid var(--mpac-main-30);
}

.no-border-input input[type=text],
.no-border-input input[type=number],
.no-border-input textarea,
.table-with-border input[type=text],
.table-with-border input[type=number],
.table-with-border textarea {
  padding: 6px 12px;
  transition: box-shadow 200ms;
  margin: 0px;
  display: table-cell;
  width: 100%;
  border: 0;
}
.no-border-input input[type=radio],
.no-border-input input[type=checkbox],
.no-border-input select,
.table-with-border input[type=radio],
.table-with-border input[type=checkbox],
.table-with-border select {
  transition: box-shadow 200ms;
  margin: 0px;
  display: table-cell;
  border: 0;
}

.red-button {
  color: white;
  background-color: var(--mpac-darker-red);
}

.blue-button {
  color: white;
  background-color: var(--mpac-main-100);
}

.pier-datatable th.pier-datatable-title {
  text-align: center;
}

.pier-datatable-title-container {
  width: 100%;
  text-align: center;
  background-color: var(--mpac-main-20);
}

.pier-datatable th.pier-datatable-clear-th {
  background-color: white;
  text-align: center;
  font-weight: normal;
}

.pier-datatable th.pier-datatable-centered-th {
  text-align: center;
}

.pier-datatable-container {
  display: inline-block;
  width: 100%;
}

.pier-datatable-title-container {
  width: 100%;
  text-align: center;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  font-weight: bold;
  color: var(--mpac-main-100);
  padding: 5px 0px;
  background-color: var(--mpac-main-10);
}