.pagination-container {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.pagination {
  display: inline-flex;
  list-style: none;
  padding: 0;
}

.pagination > li {
  border: 0.5px solid var(--mpac-main-30);
  padding: 0.5em 1em;
}

.pagination > li > a {
  cursor: pointer;
}

.pagination > li:hover, .selected {
  background-color: var(--mpac-hyperlink);
  border: none;
  color: white
}