.portal-root-layout {
    display: flex;
    flex-direction: column;
    height: 99.8vh;
    width: 99.8vw;
    overflow-y: hidden;
    overflow-x: hidden;
}

.portal-root-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 4em;
}

.portal-root-body {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: calc(99.8vh - 4em);
}

.portal-sidebar {
    background-color: var(--mpac-main-10);
    width: fit-content;
    scrollbar-color: var(--mpac-light-grey-border) var(--mpac-main-10);
    overflow-y: overlay;
    scrollbar-gutter: auto;
}

.portal-root-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.portal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: overlay;
    scrollbar-gutter: stable;
    scrollbar-color: var(--mpac-light-grey-border) white;
    padding: 0em 2em 2em 2em;
}

.portal-header {
    border-bottom: 0.35px solid var(--mpac-main-20);
}

.portal-breadcrumb {
    padding: 0.3rem 2rem 0.3rem 2rem;
}

.portal-footer {
    border-top: 0.35px solid var(--mpac-main-20);
    max-height: 4em;
}

.portal-content-padding-left {
    padding-left: 1.5em;
}

.portal-content-padding-right {
    padding-right: 1.5em;
}

.portal-content-padding-top {
    padding-top: 1.5em;
}

.portal-content-padding-bottom {
    padding-bottom: 1.5em;
}

/* Media queries */
@media screen and (min-width: 1440px) {
    .portal-root-layout {
        /* margin: 0 15% 0 15%; */
        border: 0.35px solid var(--mpac-main-20);
    }
}

.strong {
    font-weight: 600;
}

.trash {
    color: var(--mpac-darker-red);
}

.flex {
    display: flex;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.border {
    border: 1px solid var(--mpac-main-30) !important;
    border-collapse: collapse;
}

.table-with-border,
.table-with-border th,
.table-with-border td {
    border: 1px solid var(--mpac-main-30);
    border-collapse: collapse;
}

.table-with-no-border,
.table-with-no-border th,
.table-with-no-border td {
    border: 0px !important;
    border-collapse: collapse;
}

@media screen and (min-width: 2560px) {
    .border {
        border: 0.0625rem solid var(--mpac-main-30) !important;
    }

    .table-with-border,
    .table-with-border th,
    .table-with-border td {
        border: 0.0625rem solid var(--mpac-main-30);
    }

    .table-with-no-border,
    .table-with-no-border th,
    .table-with-no-border td {
        border: 0px !important;
        border-collapse: collapse;
    }
    }

th {
    font-weight: bold;
    color: var(--mpac-main-100);
    background-color: var(--mpac-main-10);
    padding: 5px 0px;
}

td {
    padding: inherit;
}

.sub-th {
    font-weight: inherit;
    background-color: var(--mpac-main-10);
    color: var(--mpac-main-100);
    padding: 5px 0px;
}

.border-input input[type=text],
.border-input input[type=number],
.border-input textarea {
    padding: 6px 12px;
    transition: box-shadow 200ms;
    margin: 0px;
    display: table-cell;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--mpac-main-30);
    background: transparent;
}

.border-input input[type=radio],
.border-input input[type=checkbox],
.border-input select {
    transition: box-shadow 200ms;
    margin: 0px;
    display: table-cell;
    border: 1px solid var(--mpac-main-30);
    background: transparent;
}

.no-border-input input[type=text],
.no-border-input input[type=number],
.no-border-input textarea,
.table-with-border input[type=text],
.table-with-border input[type=number],
.table-with-border textarea {
    padding: 6px 12px;
    transition: box-shadow 200ms;
    margin: 0px;
    display: table-cell;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 0px;
}

.no-border-input input[type=radio],
.no-border-input input[type=checkbox],
.no-border-input select,
.table-with-border input[type=radio],
.table-with-border input[type=checkbox],
.table-with-border select {
    transition: box-shadow 200ms;
    margin: 0px;
    display: table-cell;
    border: 0px;
    background: transparent;
}

.table-striped>tbody>tr:nth-child(even) {
    background-color: rgba(229, 235, 248, 0.7);
}

.table-striped>tbody>tr:nth-child(odd) {
    background-color: white;
}

.table-striped .sub-th~tr:nth-of-type(odd) {
    background-color: var(--mpac-main-10) !important;
}

.table-striped .sub-th~tr:nth-of-type(even) {
    background-color: white !important;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.justify-content-space-between {
    justify-content: space-between;
}

.height-100 {
    height: 100%;
}

.width-100 {
    width: 100%;
}

.partial-width {
    width: 80%;
}

.pie-width {
    width: 60%;
}

@media screen and (max-width: 1200px) {
    .portal-root-footer {
        height: 7em;
    }

    .portal-footer {
        max-height: 7em;
    }

    .portal-root-body {
        max-height: calc(99.8vh - 7em);
    }
}
