.modal-bg {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1060;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.modal-container {
    border-radius: 0.3125em;
    background: #fff;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding-left: 15px;
    position: relative;
    text-align: left;
    word-wrap: break-word;
    max-height: fit-content;
    overflow-y: auto;
    scrollbar-gutter: stable;
    overflow-x: hidden;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid #dee2e6;
    /* padding-top: 0.5em;
    padding-bottom: 0.5em; */
    /* padding-left: 1em;
    padding-right: 0.5em; */
    /* margin-bottom: 1.25em; */
}

.modal-dialog {
    overflow-y: initial !important
}

.modal-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow-y: auto !important;
    /* padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.0em;
    padding-right: 0.5em; */
    /* margin-bottom: 1.25em; */
}

.modal-title {
    color: var(--mpac-main-100);
    font-family: Quicksand, sans-serif;
    font-weight: bold;
}

.modal-container .btn-grp {
    text-align: right;
}

.modal-container .btn-grp button {
    margin-left: 5px;
}

.modal-header>p {
    margin: 0;
}

.btn-grp {
    border-top: 1px solid #dee2e6;
    padding-top: 10px;
    padding-bottom: 10px;
}

.close-btn button {
    background: none;
    border: none;
    box-shadow: none;
}

.close-btn button:hover,
.close-btn button:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.close-btn svg {
    font-size: 1.5em;
}

.close-btn svg:hover {
    color: var(--mpac-red)
}