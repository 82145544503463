.guided-wizard-warning-buttons {
    width: 100%;
    text-align: right;
    margin: 15px auto;
}
.guided-wizard-warning-cancel {
    margin-right: 15px;
}
.guided-experience-wizard {
    height: 600px;
    min-width: 400px;
    max-width: 1230px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.guided-experience-wizard.multires-wizard {
    height: 400px;
    width: 70vw;
}
.guided-experience-wizard td label{
    white-space: nowrap;
}
.guided-experience-wizard .marginless{
    margin: 0;
}
.guided-experience-wizard .remove-button {
    background: transparent;
    padding: 0;
}
.guided-experience-wizard .pier-datatable-container{
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.guided-experience-wizard fieldset, .wizard-radio-cell:not(:has(fieldset)) {
    display: flex;
    padding: 0;
    border: 0;
}
.guided-experience-wizard legend {
    margin-bottom: 10px;
}
.guided-experience-wizard-buttons table {
    width: 98%;
}
.guided-experience-wizard-buttons table tr {
    padding-bottom: 15px;
}
.guided-experience-wizard-buttons table tr td{
    width: 25%;
    text-align: center;
    padding: 10px 5px;
}
.guided-experience-wizard-buttons button {
    width: 100% !important;
}
.wizard-table {
    border-spacing: 0;
    width: 100%;
}
.wizard-table p{
    margin: 0;
}
.wizard-table th {
    text-align: center;
    padding: 0 5px;
    border: 0.5px solid var(--mpac-main-30);
}
.wizard-table td{
    border: 0.5px solid var(--mpac-main-30);
    padding: 5px;
}
.wizard-table td input:not([type='checkbox']):not([type='radio']) {
    width: 100%;
    margin: 0;
    border: none;
    padding: 0;
}
.wizard-table td input:not([type='checkbox']):not([type='radio']):hover, .wizard-table td input:not([type='checkbox']):not([type='radio']):focus {
    box-shadow: none;
}
.wizard-table td .checkmark {
    display: block;
    width: 15px;
    height: 15px;
    margin-right:5px;
    position: relative;
}
.wizard-table td .checkmark:not(:first-child) {
    margin-left: 15px;
}
.wizard-radio-cell span:not(:last-child) {
    margin-right: 10px;
}
.wizard-grey-out-cell:empty, .no-input-cell{
    background-color: var(--mpac-lighter-grey);
}
.wizard-grey-out-cell label {
    font-weight: normal;
}
.no-padding-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.wizard-question {
    margin-bottom: 15px;
}
.no-input-cell p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.center-cell {
    text-align: center;
}
.center-cell>span>span{
    justify-content: center;
}
.tenant-name-cell p {
    max-width: 180px;
}
.tenant-unit-cell p {
    max-width: 150px;
}
.no-input-cell.small-cell p, .no-input-cell.small-cell label{
    max-width: 80px;
}
.wizard-table .datepicker-container {
    width: 100%;
}
.wizard-table .datepicker-container input{
    background: transparent !important;
    border: none !important;
    min-width: 100px;
}
.wizard-table .react-datepicker-wrapper {
    display: inline;
}
.wizard-table select{
    width: 100%;
    padding: 0;
    border: none;
}
.wizard-table .currency-symbol {
    display: none;
}
.wizard-table .checkbox-cell label {
    margin-bottom: -2px;
    padding-right: 11px;
}
.wizard-table .checkbox-cell .checkbox-container {
    width: auto;
    height: auto;
}
.wizard-table td label {
    display: block;
    min-height: 20px;
}
.multires-wizard-parking-rate .currency-symbol {
    display: none;
}
.multires-wizard-parking-rate .currency-input{
    max-width: 165px;
    border: 1px solid var(--mpac-main-30);
    margin: 10px 0px;
}