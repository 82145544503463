.currency-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.currency-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.currency-container input {
    width: 100%;
    height: 100%;
    margin: 0px;
    border: 0px;
    box-sizing: border-box;
}

.currency-container input,
.currency-container input:focus,
.currency-container input:hover {
    box-shadow: none;
}

.currency-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-align: right;
    border: 0px;
    margin: 0px;
    background: transparent;
}

.currency-input:read-only {
    pointer-events: none;
}

.currency-symbol {
    padding: 0px 6px;
    text-wrap: nowrap;
}

input:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
}

.disabled-currency-wrapper {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}