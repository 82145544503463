.bed-counts-table {
    width: 100%;
}

.bed-counts-table th,
.bed-counts-table td {
    text-align: left;
    padding: 5px 10px;
}

.bed-counts-input-cell {
    text-align: center !important;
}

.bed-counts-input-cell input {
    max-width: 60px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--mpac-main-30) !important;
    text-align: center;
}

.bed-counts-table input:hover,
.bed-counts-table input:focus {
    box-shadow: none;
}