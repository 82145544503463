.toc-container {
    width: min(900px, 90vw);
    padding: 20px 30px;
    box-sizing: border-box;
}

.toc-paragraph {
    padding: 0.5rem;
}

.toc-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px;
    margin-top: 30px;
}

.toc-container a:not(.toc-questions p a) {
    color: black;
    font-weight: bold;
    text-decoration: underline !important;
}

.toc-questions p {
    font-size: 0.68rem;
    margin: 0;
}

.toc-print button {
    color: black;
    background: transparent;
    border: 1px solid var(--mpac-light-grey-border);
    margin: 0 5px;
    padding: 5px 10px;
    transition: box-shadow .2s;
}

.toc-print button p {
    margin: 0;
}