.full-input-data {
    position: relative;
    min-width: 7em;
}

.dollar-sign {
    float: left;
    padding: 0.25em;
}

.normal-input {
    text-align: center;
}

.table-input {
    width: 75%;
}

.cell-error {
    border: 1.5px solid var(--mpac-darker-red) !important;
}

.error-message {
    color: var(--mpac-darker-red);
    font-size: 0.8rem;
    margin-top: 5px;
}

.table-row-container {
    display: flex;
    align-items: center;
    justify-content: center;
}