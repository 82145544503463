.aoda-text-size {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
}

@media screen and (min-width: 2560px) {
  .aoda-text-size {
    padding-bottom: 0.3rem;
  }
  
}

.aoda-text-btn {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.aoda-small {
  height: 65%;
  min-width: 15px;
  min-height: 15px;
  margin: 0 1px;
}

.aoda-medium {
  height: 70%;
  min-width: 17px;
  min-height: 17px;
  margin: 0 1px;
}

.aoda-large {
  height: 75%;
  min-width: 19px;
  min-height: 19px;
  margin: 0 1px;
}

@media screen and (min-width: 2560px) {
  .aoda-small {
    min-width: 0.9375rem;
    min-height: 0.9375rem;
    margin: 0 0.0625rem;
  }
  
  .aoda-medium {
    min-width: 1.0625rem;
    min-height: 1.0625rem;
    margin: 0 0.0625rem;
  }
  
  .aoda-large {
    min-width: 1.1875rem;
    min-height: 1.1875rem;
    margin: 0 0.0625rem;
  }
}