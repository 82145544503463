.suite-breakdown-rent-to-tenant {
    display: flex;
    margin: 20px 0;
}
.suite-breakdown-rent-to-tenant p {
    margin: 0 30px 0 0;
}
.suite-breakdown-rent-to-tenant #rent-to-tenant-radio-yes {
    margin-right: 1.25rem;
    width: 0.875rem;
    height: 0.875rem;
}

.suite-breakdown-header-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.breakdown-table-section {
    display: flex;
    flex-direction: column;
}

.suite-breakdown-header-row button {
    padding: 0.75em 1em;
}

.suite-breakdown-header-row .button-submit{
    margin-left: 0.25em;
}

.suite-breakdown-table input, .suite-breakdown-table select {
    border: none;   
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
}

.error-input {
    margin-top: 0.7rem !important;
}

.error-cell {
    border: 1.5px solid var(--mpac-darker-red) !important;
}

/* .suite-breakdown-table th {
    width: 17%;
}
.suite-breakdown-table th:first-child {
    width: 24%;
} */

.suite-breakdown-table td:nth-last-child(-n+2) button {
    width: 100%;
    background: transparent;
    border: none;
}
.suite-breakdown-table td:nth-last-child(-n+2) button:hover {
    box-shadow: none;
    color: var(--mpac-darker-red);
}
.suite-breakdown-table td:nth-last-child(-n+2) button:focus {
    box-shadow: none;
}

.suite-breakdown-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.suite-breakdown-container .modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 20px;
}
.suite-breakdown-container .modal-body button {
    width: 110px;
}

.guided-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    max-width: 30em;
}

.guided-modal-button-container {
    justify-content: flex-end;
    display: flex;
    column-gap: 5px;
    padding: 1em 0;
}

.suite-control-group {
    display: flex;
    align-items: center;
}

.error-border {
    border-color: red;
}
