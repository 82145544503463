.pie-table {
  border-spacing: 0;
  width: 100%;
}

/* Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS */
.pie-table thead {
  display: none;
}

.pie-table tr:hover {
  background-color: var(--mpac-teal-50);
}

.pie-table input {
  background-color: transparent;
}

.pie-table th,
.pie-table td {
  height: 2rem;
  vertical-align: middle;
  padding: 0px;
  text-wrap: nowrap;
}

.pie-table .label {
  padding-left: 12px;
  padding-right: 12px;
}

.pie-table .value {
  width: 12rem;
  max-width: 12rem;
  min-width: 12rem;
}

.pie-table .sub-th {
  padding: 0px;
}

.capital-expenditures-table {
  width: 80%;
}

.capital-expenditures-table tr th:first-child {
  width: 18.75rem;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.reserveTotalTitle {
  padding-left: 1rem;
}

.reserveTotalCurrency {
  padding-left: 1rem;
  padding-right: 1rem;
}

.total-container {
  position: sticky;
  position: -webkit-sticky;
  bottom: -2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--mpac-background);
  padding-top: 2em;
}