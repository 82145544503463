.sidebar-large-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0.625rem 0;
    border-radius: 0.3rem;
}

.sidebar-large-item-text {
    margin: 0 0 0 1.2rem;
    font-size: 1rem;
}

.sidebar-large-item-active {
    background-color: var(--mpac-main-100);
    color: white;
    font-weight: bold;
}

.sidebar-large-item-inactive {
    cursor: pointer;
}

.sidebar-item-large-container {
    width: 100%;
}