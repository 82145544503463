.profile-table-body tr {
  padding: 0.65em 0;
}

.profile-table-body tr:hover {
  cursor: pointer;
}

.profile-table-body td:not(:last-child) {
  padding-right: 2rem;
}

.action-icon-list svg {
  margin: 0 0.5rem;
}

.action-icon-list svg:hover {
  color: var(--mpac-main-60);
}

.status {
  margin: 1em;
}