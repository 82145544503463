.comment-textbox {
  min-height: 30vh;
  margin: 0;
}

.comment-textbox:disabled {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))
}

.internal-comment {
  border: 1px solid var(--mpac-main-100);
}