/* Asterisk */
.component-asterisk {
    color: var(--mpac-darker-red);
    font-weight: bold;
}
/* Radio */
.component-radio {
    display: flex;
    align-items: flex-start;
}
.component-radio.left {
    flex-direction: row-reverse;
}
.component-radio.top{
    flex-direction: column-reverse;
}
.component-radio.below {
    flex-direction: column;
}
.component-radio label{
    margin: 0;
    font-weight: normal;
}
.component-radio input[type="radio"] {
    margin: 0.25rem 0 0 0;
    width: 0.875rem;
    height: 0.875rem;
}
.component-radio.top input[type="radio"]{
    margin-top: 0.25rem;
}
.component-radio.left label{
    margin-right: 0.3rem;
}
.component-radio.right label{
    margin-left: 0.3rem;
}
/* Dropdown */
.dropdown-title {
    margin-bottom: 15px;
    font-size: 1rem;
}

.red-cross {
    color: var(--mpac-darker-red);
    padding: 0px 10px
}

.input-error-message {
    margin-top: 10px;
    color: var(--mpac-darker-red);
}

.button-submit {
    font-weight: bold;
    color: white;
    background: var(--mpac-main-100);
    border-radius: 0.3rem;
    border: 1.4px solid var(--mpac-main-100);
}

@media screen and (max-width: 2560px) {
    .button-submit {
        border: 0.07rem solid var(--mpac-main-100);
    }
}

/* Table */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.border {
    border: 1px solid var(--mpac-main-30) !important;
    border-collapse: collapse;
}

.table-with-border th,
.table-with-border td {
    border: 1px solid var(--mpac-main-30);
    border-collapse: collapse;
}

@media screen and (min-width: 2560px) {
    .border {
        border: 1px solid var(--mpac-main-30) !important;
    }

    .table-with-border th,
    .table-with-border td {
        border: 0.0625rem solid var(--mpac-main-30);
    }
}

th {
    font-weight: bold;
    color: var(--mpac-main-100);
    background-color: var(--mpac-main-10);
    padding: 5px;
}

.button-cancel:focus {
    outline: none;
}

.button-disabled {
    font-weight: bold;
    color: var(--mpac-light-grey-border);
    background: white;
    border-radius: 5px;
    border: 1.4px solid var(--mpac-light-grey-border);
}

.button-disabled:hover, .button-disabled:focus {
    outline: none;
    box-shadow: none;
    cursor: default;
}