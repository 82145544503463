/* Customize the label (the container) */
.radio-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 19px;
  height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: transparent;
  border: 1px solid var(--mpac-main-30);
  border-radius: 50%;
}

/* On mouse-over */
.radio-container:hover input~.radiomark {
  box-shadow: 1px 1px 10px var(--mpac-main-30);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.radiomark {
  background-color: var(--mpac-main-100);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radiomark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radiomark:after {
  background: var(--mpac-main-100);
  ;
}

.radio-container:has(input:disabled) {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  cursor: not-allowed;
}