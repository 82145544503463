
.default {
    background-color: var(--mpac-charcoal-50) !important;
}

.secondary {
    background-color: var(--mpac-lemonmeringue) !important;
}

.space {
    background-color: var(--mpac-background) !important;
}