.landing-banner {
    width: 100%;
    height: 13em;
    margin-bottom: 1em;
}
.landing-banner-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (min-width: 1440px) {
    .landing-banner {
        width: 100%;
        height: 15em;
    }
}

@media screen and (min-width: 2560px) {
    .landing-banner {
        width: 100%;
        height: 11em;
        margin-bottom: 1em;
    }
}